<template>
  <div id="pc_header">
    <!-- 一级菜单目录 -->
    <div class="pc_header">
      <div class="logo" @click="goBack()">
        <img src="@/assets/logo.png" width="100%" height="100%" />
      </div>
      <div class="shortcut">
        <ul>
          <li
            v-for="(item, index) in this.shortcut"
            :key="index"
            @mouseenter="mouseEnter_Active(index)"
            @mouseleave="mouseLeave_Active()"
            @click="zxc"
          >
            <a
              v-bind:id="index == isactive ? 'bg1' : 'bg'"
              href="javascript:;"
              @click="active(index)"
              >{{ item }}</a
            >
          </li>
        </ul>
      </div>
    </div>
    <!-- 二级菜单目录 -->
    <!--     -->
    <div
      class="secondary_menu"
      :class="mouseIsactive == 1 ? 'active' : ''"
      v-show="mouseIsactive == 1"
      @mouseleave="secondaryLeave()"
      @mouseenter="secondaryEnter()"
    >
      <ul>
        <li
          v-for="(item, index) in secondary_menu"
          :key="index"
          @click="secondaryMenu(index)"
        >
          {{ item }}
        </li>
      </ul>
    </div>
    <!-- 产品二级菜单 -->
    <div
      class="secondary_menu1 "
      :class="productActive == 2 ? 'active' : ''"
      v-show="productActive == 2"
      @mouseleave="productLeave()"
      @mouseenter="productEnter()"
    >
      <ul>
        <li
          v-for="(item, index) in productMenu"
          :key="index"
          @click="productMenus(index)"
        >
          {{ item }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
//这里可以导入其他文件（比如：组件，工具 js，第三方插件 js，json 文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
export default {
  //import 引入的组件需要注入到对象中才能使用
  name: "pc-header",
  components: {},

  props: {},
  data() {
    //这里存放数据
    return {
      searchCenter: "",
      shiftShow: true,
      isactive: 0,
      mouseIsactive: 0,
      productActive: 0,
      shortcut: ["首页", "关于我们", "产品中心", "解决方案", "联系我们"],
      secondary_menu: ["公司简介", "组织架构", "公司历程"],
      productMenu: ["数据中台", "边缘计算"],
    };
  },
  //计算属性 类似于 data 概念
  computed: {},
  //监控 data 中的数据变化
  watch: {},
  //方法集合
  methods: {
    showCatalogue() {
      if (this.$route.query.items) {
        this.mouseIsactive = 1;
      }
    },
    zxc() {},
    // 点击logo
    goBack() {
      this.$router.go(0);
      this.$router.push({ path: "/" });
    },
    // 点击一级菜单
    active(e) {
      this.isactive = e;
      if (e == 0) {
        this.$router.push({ path: "/" });
      } else if (e == 2) {
        this.$router.push({ path: "/dataCenter", query: { item: e } });
      } else if (e == 1) {
        this.$router.push({ path: "/companyProfile", query: { items: 0 } });
      } else if (e == 3) {
        this.$router.push({ path: "/solution", query: { item: e } });
      } else if (e == 4) {
        this.$router.push({ path: "/contactUs", query: { item: e } });
      }
    },
    mouseEnter_Active(e) {
      if (e == 1) {
        this.mouseIsactive = e;
        this.productActive = 0;
      } else if (e == 2) {
        this.mouseIsactive = 0;
        this.productActive = e;
      }else{
        this.productActive = 0;
        this.mouseIsactive = 0;
      }
    },
    mouseLeave_Active() {},
    secondaryEnter() {
      this.mouseIsactive = 1;
    },
    secondaryLeave() {
      this.mouseIsactive = 0;
    },
    productLeave() {
      this.productActive = 0;
    },
    productEnter() {
      this.productActive = 2;
    },
    // 点击二级菜单
    productMenus(e) {
      this.showCatalogue();
      if (e == 0) {
        this.$router.push({ path: "/dataCenter", query: { items: e } });
      } else if (e == 1) {
        this.$router.push({
          path: "/edgeComputing",
          query: { items: e },
        });
      }
      this.mouseIsactive = 0;
      this.isactive = 2;
    },
    secondaryMenu(e) {
      this.showCatalogue();

      if (e == 0) {
        this.$router.push({ path: "/CompanyProfile", query: { items: e } });
      } else if (e == 1) {
        this.$router.push({
          path: "/organizationalStructure",
          query: { items: e },
        });
      } else if (e == 2) {
        this.$router.push({ path: "/companyHistory", query: { items: e } });
      } else if (e == 3) {
        this.$router.push({ path: "/CorporateCulture", query: { items: e } });
      }
      this.mouseIsactive = 0;
      this.isactive = 1;
    },
  },
  //生命周期 - 创建完成（可以访问当前 this 实例）
  created() {
    this.showCatalogue();
  },
  //生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    // if (this.$route.query.item) {
    //   this.isactive = this.$route.query.item;
    // } else {
    //   this.isactive = 0;
    // }
    // if (this.$route.query.items) {
    //   this.isactive = 1;
    // }
  },
};
</script>
<style lang="scss" scoped>
/* @import url(); 引入公共 css 类 */
#pc_header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 90px;
  z-index: 9999;
  transition: all 0.5s;
  min-width: 1200px;
  &:hover {
    background: rgb(0, 0, 0, 0.8);
  }

  .pc_header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 90px;
    // background: #343434;
    div {
      display: flex;
      /* 主轴居中对齐。 */
      justify-content: center;
      /* 侧轴居中对齐。 */
      align-items: center;
    }
    .logo {
      width: 60px;
      height: 60px;
      margin-left: 20%;
      margin: auto 20%;
      cursor: pointer;
    }
    .shortcut {
      width: 50%;
      margin-right: 75px;
      ul {
        width: 100%;
        display: flex;
        li {
          position: relative;
          text-align: center;
          width: 15%;
          font-size: 16px;
          font-weight: 400;
          img {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 0);
          }
        }
      }
    }
    #bg1 {
      color: #917e71;
    }
    #bg {
      color: #ffffff;
    }
  }
  .secondary_menu {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -50%;
    transition: all 0.5s;
    // background-image: url(../../../assets/pc/logo/drop_down_box.png);
    height: 0px;
    z-index: 999;
    ul {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: flex-start;

      margin-left: 51%;
      li {
        cursor: pointer;
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: white;
        margin-right: 40px;
      }
    }
  }
  .secondary_menu1 {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -50%;
    transition: all 0.5s;
    // background-image: url(../../../assets/pc/logo/drop_down_box.png);
    height: 0px;
    z-index: 999;
    ul {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: flex-start;
      margin-left: 61%;
      li {
        cursor: pointer;
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        margin-right: 40px;
      }
    }
  }
}
.active {
  background-color: rgb(0, 0, 0, 0.5);
  height: 60px !important;
  top: 90px;
}
</style>
