<template>
  <div id="app">
    <headerBar></headerBar>
    <router-view />
    <page-footing></page-footing>
  </div>
</template>
<script>
import headerBar from "@/components/header/index.vue";

import pageFooting from "@/components/bottom/pageFooting.vue";
export default {
  components: {
    headerBar,
    pageFooting,
  },
};
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
</style>
