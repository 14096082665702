import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: () => import('@/views/contactUs'),
  },
  {
    path: '/dataCenter',
    name: 'dataCenter',
    component: () => import('@/views/productCenter/dataCenter.vue'),
  },
  {
    path: '/edgeComputing',
    name:'edgeComputing',
    component: () => import('@/views/productCenter/edgeComputing.vue'),
  },
  {
    path: '/CompanyHistory',
    name: 'CompanyHistory',
    component: () => import('@/views/aboutUS/CompanyHistory/CompanyHistory.vue'),
  },
  {
    path: '/CompanyProfile',
    name: 'CompanyProfile',
    component: () => import('@/views/aboutUS/CompanyProfile/CompanyProfile.vue'),
  },
  {
    path: '/CorporateCulture',
    name: 'CorporateCulture',
    component: () => import('@/views/aboutUS/CorporateCulture/CorporateCulture.vue'),
  },
  {
    path: '/organizationalStructure',
    name: 'organizationalStructure',
    component: () => import('@/views/aboutUS/organizationalStructure/organizationalStructure.vue'),
  },
  {
    path: '/solution',
    name: 'solution',
    component: () => import('@/views/solution/solution.vue'),
  }, 
  {
    path: '/networkManagement',
    name: 'networkManagement',
    component: () => import('@/views/detailList/networkManagement.vue'),
  },
  {
    path: '/newEnergy',
    name: 'newEnergy',
    component: () => import('@/views/detailList/newEnergy.vue'),
  },
  {
    path: '/comprehensiveEnergy',
    name: 'comprehensiveEnergy',
    component: () => import('@/views/detailList/comprehensiveEnergy.vue'),
  },
  {
    path: '/intelligentTransportationInspection',
    name: 'intelligentTransportationInspection',
    component: () => import('@/views/detailList/intelligentTransportationInspection.vue'),
  }, 
  {
    path: '/failurePrediction',
    name: 'failurePrediction',
    component: () => import('@/views/detailList/failurePrediction.vue'),
  },
  {
    path: '/securityIntelligentMonitoring',
    name: 'securityIntelligentMonitoring',
    component: () => import('@/views/detailList/securityIntelligentMonitoring.vue'),
  }, 
  {
    path: '/manufacturingExecutionSystem',
    name: 'manufacturingExecutionSystem',
    component: () => import('@/views/detailList/manufacturingExecutionSystem.vue'),
  },
  {
    path: '/digitalOperation',
    name: 'digitalOperation',
    component: () => import('@/views/detailList/digitalOperation.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
