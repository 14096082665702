<template>
  <div class="home">
    <div class="homeBanner">
      <el-carousel height="400px">
        <el-carousel-item v-for="(item, index) in bannerList" :key="index">
          <img :src="item" height="100%" width="100%" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="About-us main">
      <div class="About-us-center">
        <div class="About-us-left">
          <div class="title">
            <h3>企业愿景</h3>
            <h4>VISION</h4>
            <p>
              技术赋能行业伙伴
            </p>
            <p>
              构建万物互联世界
            </p>
            <p>
              推进行业智能浪潮
            </p>

          </div>
        </div>
        <div class="About-us-right">
          <div class="rightImg">
            <div class="topimgbox">
              <img src="../assets/image/vision-01.jpg" height="100%" width="100%" />
            </div>
            <div class="leftimgbox">
              <img src="../assets/image/vision-2.png" height="100%" width="100%" />
            </div>
            <div class="rightimgbox">
              <img src="../assets/image/vision-3.jpg" height="100%" width="100%" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="SERVICE-area main">
      <titleCenter title="服务范围" Entitle="SERVICE AREA"></titleCenter>

      <div class="SERVICE-cen">
        <div class="SERVICE-box">
          <img src="../assets/image/CloudData.webp" />
          <p class="CompositeBigData">端：物联网平台</p>
          <p class="SERVICE-after"></p>
          <p>连接和管理物联网设备，提供数据分析、安全性和应用开发的解决方案。</p>
        </div>
        <div class="SERVICE-box">
          <img src="../assets/image/CompositeBigData.webp" />
          <p class="CompositeBigData">边：边缘计算</p>
          <p class="SERVICE-after"></p>
          <p>边缘计算智能网关进行本地数据采集，并进行数据过滤、清洗等实时处理。同时边缘计算还可以提供跨层协议转换的能力，实现碎片化工业网络的统一接入。</p>
        </div>
        <div class="SERVICE-box">
          <img src="../assets/image/bigData.webp" />
          <p class="CompositeBigData">云：数据中台</p>
          <p class="SERVICE-after"></p>
          <p>数据中台将业务数据、物联网数据进行连接、共享；解决数据孤岛问题的同时，形成标准化数据资产。</p>
        </div>
        <div class="SERVICE-box">
          <img src="../assets/image/IntelligentMultimedia.webp" />
          <p class="CompositeBigData">管：网络探针</p>
          <p class="SERVICE-after"></p>
          <p>获取网络节点的上网数据，通过数据建模手段实现复杂的关联分析和流量建模，进行路由和质量分析、服务差异化分析以及为增值业务提供辅助技术服务等。</p>
        </div>
      </div>
    </div>
    <div class="SERVICE-CASE main">
      <titleCenter title="服务案例" Entitle="SERVICE CASE"></titleCenter>
      <div class="SERVICE-CASE-Carousel">
        <el-carousel indicator-position="none" :autoplay="false">
          <el-carousel-item v-for="(item, index) in caseList" :key="index">
            <div class="caseBox" v-for="(el, index) in item" :key="index">
              <img :src="el.url" height="80%" width="100%" alt="" />
              <p>{{ el.name }}</p>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="PARTNERS">
      <titleCenter title="合作伙伴" Entitle="PARTNERS"></titleCenter>
      <div class="PARTNERS-cen">
        <img src="../assets/image/partners.png" height="80%" width="70%" />
      </div>
    </div>
    <el-backtop :visibility-height="200"></el-backtop>
  </div>
</template>

<script>
// @ is an alias to /src
import one from "@/assets/image/case-phm.jpg";
import two from "@/assets/image/case-5g.jpg";
import three from "@/assets/image/case-edge.jpeg";
import four from "@/assets/image/case-city.jpg";
import titleCenter from "@/components/title-center";
import banner1 from "@/assets/image/banner1.png";
import banner2 from "@/assets/image/banner2.png";
export default {
  name: "HomeView",
  data() {
    return {
      bannerList: [banner1, banner2],
      caseList: [
        [
          { url: one, name: "机车PHM" },
          { url: two, name: "5G仿真" },
          { url: three, name: "边缘计算监测" },
          { url: four, name: "市域治理" },
        ],
      ],
    };
  },
  components: {
    titleCenter,
  },
};
</script>
<style scoped lang="scss">
.main {
  width: 1200px;
  margin: 0 auto;
}

.About-us {
  height: 700px;
  .About-us-center {
    width: 1260px;
    height: 100%;
    margin: 0 auto;
    // background-color: blue;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .About-us-left {
      width: 500px;
      height: 100%;
      // margin: 0 auto;
      padding: 0 auto;
      position: relative;
      display: flex;
      align-items: center;
      &::after {
        content: "";
        position: absolute;
        left: 100px;
        top: 0;
        width: 0px;
        height: 200px;
        border-left: 1px solid #ccc;
      }
      .title {
        // margin-top: 200px;
        height: 400px;
        padding: auto 0;
        // display: flex;
        // flex-wrap: wrap;
        // justify-content: start;
        // align-items: center;
        h3 {
          width: 100%;
          margin-top: 100px;
          font-weight: 300;
          font-size: 32px;
        }
        h4 {
          width: 100%;
          margin-top: 10px;
          margin-bottom: 40px;
          font-weight: 300;
          font-size: 20px;
          position: relative;
          line-height: 24px;
          color: #ccc;
          &::after {
            content: "";
            width: 5px;
            height: 5px;
            // border-radius: 50%;
            background-color: #ffffff;
            position: absolute;
            top: 7px;
            right: 380px;
          }
        }
        p {
          color: #777;
          line-height: 45px;
        }
        .Aboutbtn {
          margin-top: 40px;
          width: 200px;
          height: 50px;
          border: 1px solid red;
          color: red;
          background-color: #fff;
          padding: 0 30px;
          cursor: pointer;
          &:hover {
            background-color: red;
            color: #fff;
          }
        }
      }
    }
    .About-us-right {
      width: 500px;
      overflow: hidden;
      .rightImg {
        height: 600px;
        display: flex;
        flex-wrap: wrap;
        .topimgbox {
          display: inline-block;
          width: 100%;
          height: 300px;
          margin-bottom: 10px;
          img{
            border-radius: 5px;
          }
        }
        .leftimgbox {
          display: inline-block;
          width: 300px;
          height: 300px;
          img{
            border-radius: 5px;
          }
        }
        .rightimgbox {
          margin-left: 10px;
          display: inline-block;
          width: 190px;
          img{
            border-radius: 5px;
          }
        }
      }
    }
  }
}
.SERVICE-area {
  padding: 100px 0 40px 0;
  // margin-top: 30px;

  .SERVICE-cen {
    height: 500px;
    display: flex;
    align-items: center;
    .SERVICE-box {
      flex: 1;
      height: 200px;
      margin: 20px 20px;
      text-align: center;
      img {
        width: 100px;
        height: 100px;
      }
      .CompositeBigData {
        line-height: 24px;
        font-size: 16px;
        color: rgba(68, 68, 68, 1);
        position: relative;
      }
      .SERVICE-after {
        height: 0;
        color: rgba(204, 204, 204, 1);
        border-bottom: 1px solid rgba(204, 204, 204, 1);
        margin: 20px 120px;

        // position: absolute;
        // bottom: -10px;
        // left: 34%;
      }
      p {
        font-size: 14px;
        color: rgba(119, 119, 119, 1);
      }
    }
  }
}
.SERVICE-IDEA {
  width: 100%;
  height: 780px;
  background-color: #f6f2f5;
  margin-bottom: 100px;
  display: flex;
  .SERVICE-IDEA-img {
    width: 840px;
    height: 100%;
  }
  .SERVICE-IDEA-Center {
    width: calc(100% - 840px);
    height: 100%;
    // background-color: red;
    padding: 170px 150px;
    h3 {
      font-size: 26px;
      font-weight: 300;
      line-height: 40px;
    }
    h4 {
      width: 200px;
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      color: rgba(119, 119, 119, 1);
    }
    .SERVICE-IDEA-ImgBox {
      margin: 50px 0;
      width: 600px;
      height: 340px;
      border: 2px solid #dedde0;
      transition: all 2s ease-in-out;
      padding: 5px;
      &:hover {
        border: 2px solid red;
      }
    }
    p {
      font-size: 14px;
      color: #777777;
    }
    .Aboutbtn {
      margin-top: 40px;
      width: 200px;
      height: 50px;
      border: 1px solid red;
      color: red;
      background-color: #fff;
      padding: 0 30px;
      cursor: pointer;
      &:hover {
        background-color: red;
        color: #fff;
      }
    }
  }
}
.SERVICE-CASE {
  height: 640px;
  .SERVICE-CASE-Carousel {
    margin: 100px 0;
    .el-carousel__item {
      display: flex;

      .caseBox {
        text-align: center;
        flex: 1;
        padding: 10px 40px;
        // margin-right: 5px;
        &:last-child {
          margin: 0;
        }
        p {
          line-height: 30px;
        }
      }
    }
  }
}
.PARTNERS {
  height: 800px;
  margin: 0;

  //margin-bottom: 50px;

  .PARTNERS-cen {
    width: 95%;
    height: 95%;
    text-align: center;
    margin: 50px;
    padding: 25px;
    overflow: hidden;
    margin-top: 60px;
  }
}
</style>