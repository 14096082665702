<template>
    <div class="pageFooting">
        <div style="width:1000px;margin:0 auto;">
            <el-row :gutter="20">
                <el-col :span="9">
                        <div class="grid-content bg-purple">
                            <div class="logo">
                                <h1 class="logo-img"></h1>
                            </div>
                        </div>
                    </el-col>
                <el-col :span="10">
                        <div class="grid-content item bg-purple">
                            2024版权 ©南京绿和信息技术有限公司
                        </div>
                </el-col>

            </el-row>
        </div>
    </div>
</template>

<script>
export default {
    name: "pageFooting",
    data() {
        return { 
        
        };
    },
    created() {},
    mounted() {},
    components: {},
    props: {},
    computed: {},
    watch: {},
    methods: {
    
    },
};
</script>

<style scoped lang="scss">
.pageFooting{
    width: 100%;
    height: 200px;
    background-color: #333333;
    padding-top: 70px;
    .logo {
        width: 50%;
        line-height: 100px;
        text-align: center;
        display: flex;
        // justify-content: center;
        padding-left: 30px;
        .logo-img {
            width: 80px;
            height: 80px;
            margin: auto 0;
            padding: 0;
            background: url("../../assets/logo.png") no-repeat;
            background-size: 100% 100%;
        }
    }
    .grid-content{
        height: 60px;
        text-align: center;
        // line-height: 60px;
        color: #777;
    }
    .item{
        line-height: 70px;
    }
}
</style>