<template>
  <!-- 只能有一个div根标签 -->
  <div class="SERVICE-title">
    <h3>{{ title }}</h3>
    <h4>{{ Entitle }}</h4>
  </div>
</template>

<script>
export default {
  name: "titleCenter",
  data() {
    return {};
  },
  created() {},
  mounted() {},
  components: {},
  props: {
    title: {
      type: String,
      required: true,
    },
    Entitle: {
      type: String,
      required: true,
    },
  },
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style scoped lang="scss">
.SERVICE-title {
  width: 100%;
  margin: auto;
  text-align: center;
  position: relative;
  &::after {
    content: "";
    width: 100%;
    height: 1px;
    background-color: red;
    border-bottom: 1px solid #444444;
  }
  h3 {
    font-size: 26px;
    font-weight: 300;
    color: #444444;
    line-height: 40px;
    border-bottom: 1px solid #f5f5f5;
  }
  h4 {
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-100px);
    // margin: 0 auto;
    width: 200px;
    font-size: 16px;
    font-weight: 300;
    color: #777777;
    line-height: 24px;
    background-color: #fff;

    &::after {
      content: "";
      width: 8px;
      height: 8px;
      // border-radius: 50%;
      background-color: #e33927;
      position: absolute;
      top: 50px;
      left: 50%;
      transform: translateX(-4px);
    }
  }
}
</style>